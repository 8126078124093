header {
  display: flex;
  align-items: end;
  margin-bottom: 40px;
}
header h1{
  display: inline;
  margin-left: 20px;
  font-size: 22px;
}
#root{
  
}
.App {
  margin: 20px auto;
  max-width: 80%;
}
.logo {
  width: 150px;
  height: auto;
}
.loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(212 212 212 / 30%);
  z-index: 20;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.spinner {
  width: 48px;
  height: 48px;
  border: 5px solid #000;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.dialog {
  padding: 20px 40px;
}
.action {
  text-align: right;
  margin: 0 20px;
}
.companyItem{
  display: flex;
  align-items: center;
}
.avatar{
  margin-right: 20px;
}
ul {
  list-style: none;
}

li {
  border-bottom: 1px solid #ccc;
  margin: 10px 0;
  padding: 10px 0;
}

ul li:last-child {
  border: none;
}

@keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}